import Vue from "vue";

const TokenKey = 'SPREAD-CRM-TOKEN'

export function getToken() {
  return Vue.localStorage.get('SPREAD-CRM-TOKEN')
}

export function setToken(token) {
  return Vue.localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return Vue.localStorage.removeItem(TokenKey)
}
