import request from './request';

const token = localStorage.getItem('SPREAD-CRM-TOKEN');

export function login(username, data) {
  return request({
    url: `/auth/${username}/login`,
    method: 'post',
    data
  })
}

export function getUserSalt(username) {
  return request({
    url: `/auth/${username}/salt`,
    method: 'get'
  })
}

export function logout() {
  return request({
    url: `/auth/${token}`,
    method: 'delete'
  })
}

export function list(search) {
  return request({
    url: `/user`,
    method: 'get',
    params: {search}
  })
}

