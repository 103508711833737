<template>
  <el-container style="height: 100%">
    <div class="flex-1 flex flex-dc" style="z-index:1;width:100%">
      <div class="header-wrap flex flex-ac flex-jb" v-if="isLogin">
        <div class="title-wrap flex flex-ac">
          <img src="@/assets/images/logo.png">
          <span>Spread CRM Platform</span>
        </div>
        <div>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link flex flex-ac">
              <img v-if="avatarUrl" :src="avatarUrl" width="30px">
              <img v-else src="@/assets/images/logo.png" width="30px">
              <span class="ml5" style="color: white">{{ nickname }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="import">導入</el-dropdown-item>
              <el-dropdown-item command="logout">退出登錄</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>


        <el-dialog
            title="導入"
            :visible.sync="isImport"
            append-to-body
            width="30%">
          <div>
            <el-upload
                ref="fileUploader"
                :action="uploadUrl"
                :headers="uploadHeaders"
                :on-success="handleUploadSuccess"
                :on-error="handleUploadError"
                :show-file-list="true"
                drag
                :limit="1"
                :file-list="fileList">
              <i class="el-icon-upload pointer" style="font-size:calc(0.8vw + 8px); color:#555"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isImport = false">取 消</el-button>
          </span>
        </el-dialog>
      </div>
      <div class="flex-1 flex">
        <div class="menu-wrap" v-if="isLogin">
          <el-menu
            :default-active="menuActive"
            :collapse="isMenuCollapse"
            class="el-menu-vertical-demo"
            background-color="#555"
            text-color="#fff"
            active-text-color="#FFC000"
            style="font-weight:bold; border-right:none"
            :router="true">
            <el-menu-item class="toggle" style="cursor: default;">
              <img src="@/assets/images/icon-1.png" style="cursor: pointer;" @click="isMenuCollapse = !isMenuCollapse">
            </el-menu-item>
            <el-menu-item index="dashboard" route="/dashboard" class="border-bottom">
              <img src="@/assets/images/icon-2.png">
              <span slot="title">Dashboard</span>
            </el-menu-item>
            <el-menu-item index="company" route="/company">
              <img src="@/assets/images/icon-3.png">
              <span slot="title">公司資訊</span>
            </el-menu-item>
            <el-menu-item index="user" class="border-bottom" route="/user">
              <img src="@/assets/images/icon-4.png">
              <span slot="title">人員資訊</span>
            </el-menu-item>
            <el-menu-item index="files" route="/files">
              <img src="@/assets/images/icon-5.png">
              <span slot="title">檔案庫</span>
            </el-menu-item>
            <el-menu-item index="film" route="film" class="/border-bottom">
              <img src="@/assets/images/icon-6.png">
              <span slot="title">影片庫</span>
            </el-menu-item>
            <el-menu-item index="business-record" route="/business-record">
              <img src="@/assets/images/icon-7.png">
              <span slot="title">業務紀錄</span>
            </el-menu-item>
            <el-menu-item index="customer-focus" route="/customer-focus">
              <img src="@/assets/images/icon-8.png">
              <span slot="title">關注客戶</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="flex-1" style="position:relative">
          <router-view/>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import Vue from 'vue'
import * as userApi from '@/apis/user'
import {getToken} from "@/utils/auth";

export default {
  name: "App",
  provide () {
    return {
      reload: this.reload,
      cancelRequest: this.cancelRequest,
      syncPageMenu: this.matchActiveMenu,
    }
  },
  data() {
    return {
      isLogin: !!localStorage.getItem('SPREAD-CRM-USERNAME'),
      avatarUrl: localStorage.getItem('SPREAD-CRM-AVATAR'),
      nickname: localStorage.getItem('SPREAD-CRM-NICKNAME'),
      isRouterAlive: true,
      isMenuCollapse: false,
      isImport: false,
      menuActive: '',
      menu: [{
        label: 'dashboard',
        isActive: false
      },{
        label: 'company',
        isActive: false
      },{
        label: 'user',
        isActive: false
      },{
        label: 'files',
        isActive: false
      },{
        label: 'film',
        isActive: false
      },{
        label: 'business-record',
        isActive: false
      },{
        label: 'customer-focus',
        isActive: false
      }],
      uploadUrl: process.env.VUE_APP_BASE_API + '/crm/company/import',
      uploadHeaders: {
        'S-Token': getToken()
      },
      fileList: []
    }
  },
  created() {
    this.matchActiveMenu();
  },
  methods: {

    matchActiveMenu() {
      const href = location.href;
      this.menu.map(item => {
        if(href.indexOf(item.label) > -1){
          item.isActive = true;
          this.menuActive = item.label;
        }else{
          item.isActive = false;
        }
      });
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      })
    },

    cancelRequest() {
      // 路由跳转之前，清空（终止）上一个页面正在请求的内容(埋點請求除外)
      Vue._axiosPromiseArr.forEach((ele,index) => {
        if(ele.name !== 'RecordsUserOperation'){
          ele.cancel() 
          // 清空请求的参数
          delete Vue._axiosPromiseArr[index]
        }
      })
    },

    handleCommand(command) {
      if(command === 'logout'){
        userApi.logout();
        localStorage.setItem('SPREAD-CRM-USERINFO', '');
        localStorage.setItem('SPREAD-CRM-USERNAME', '');
        localStorage.setItem('SPREAD-CRM-AVATAR', '');
        localStorage.setItem('SPREAD-CRM-TOKEN', '');
        location.reload();
      }else if(command === 'import'){
        this.isImport = true;
      }
    },
    handleUploadSuccess(res) {
      this.$refs.fileUploader.clearFiles();
      if (res.code !== 0) {
        this.$message({
          message: res.msg || '文件上传失败',
          type: 'error'
        });
        return;
      }

      window.location.reload();
    },
    handleUploadError() {
      this.avatarList = [];
      this.$refs.fileUploader.clearFiles();
    }
  },
};
</script>

<style lang="scss">
@import './styles/element-variables.scss';

html {
  height: 100%;
  margin: auto;
}

body {
  height: 100%;
  margin: 0;
}

.header-wrap {
  background-color:#01072A;
  color:#fff;
  padding:5px 10px;

  .title-wrap {
    font-size:calc(0.6vw + 7px);

    img {
      width:calc(0.8vw + 8px);
      margin-right:5px;
    }
  }

  .el-icon-arrow-down {
    color:#fff;
  }
}

.menu-wrap {
  // width:300px;
  height:100%;
  background-color:#f5f5f5;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.4);
  z-index:1;

  .el-menu {
    background-color:#f5f5f5 !important;

    li {
      background-color:#f5f5f5 !important;
      color:#333 !important;
      transition:all 0.2s ease-in-out;

      &:hover {
        background-color:#ddd !important;
      }

      &.is-active {
        color: $--color-primary!important;

        img {
          filter: invert(63%) sepia(98%) saturate(7499%) hue-rotate(190deg) brightness(93%) contrast(101%);
        }
      }
    }

    img {
      width:calc(1vw + 10px);
      margin-right:20px;
    }

    .border-bottom {
      position:relative;

      &:before {
        content:'';
        position:absolute;
        bottom:0;
        left:10px;
        right:10px;
        border-bottom:1px solid #999;
      }
    }

    .toggle {
      &:hover {
        background-color:transparent !important;
      }
    }
  }
}

</style>