import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import {Notification, MessageBox, Message} from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: localStorage.getItem('PLATFORM_BASE_URL') || process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  headers: {
    // 'Content-Type': 'application/json;charset=utf-8'
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    let token = Vue.localStorage.get('SPREAD-CRM-TOKEN');
    if (token) {
      config.headers.common['S-Token'] = token;
    }

    // 記錄跳轉頁面后需要中止的上一個頁面的請求集合
    config.cancelToken = new axios.CancelToken(cancel => {
      Vue._axiosPromiseArr.push({
        cancel: cancel
      })
    })

    if(config.method === 'post' || config.method === 'put'){
      config.data = qs.stringify(config.data, { arrayFormat: 'repeat' });
    }

    return config
  },
  error => {  
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response
    if(res.data.code === 0) return res.data.data;
    Message.error(res.data.msg);
    return Promise.reject(res);
  },
  error => {

    if(error?.response?.status === 401){
      MessageBox.confirm('沒有權限或登錄信息過期', '提示', {
        confirmButtonText: '重新登錄',
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem('SPREAD-BOURNS-USERNAME', '');
        location.reload()
      });
      return Promise.reject();
    }

    const message = error?.response?.data && error.response?.data?.data?.List[0].Msg || 'Error code 500, 服務器請求失敗';

    Notification.error({
      title: 'Error',
      message: message
    })

    return Promise.reject(error)
  }
)

export default service
