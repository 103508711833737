import Vue from 'vue'
Vue.config.productionTip = false

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)

import App from '@/App.vue'
import router from '@/router.js'
import ElementUI from 'element-ui'
import '@/styles/element-variables.scss'

import '@/styles/index.scss' // global css
import i18n from './utils/i18n'
import * as filters from '@/filters/index' // global filters

Vue.use(ElementUI);
Vue._axiosPromiseArr = []

// register global filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
