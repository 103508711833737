import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// Router Mapping
const routes = [
  // Index
  {path: '/login', component: () => import('@/views/login/index')},
  {path: '/dashboard', component: () => import('@/views/dashboard/index')},
  {path: '/company', component: () => import('@/views/company/index')},
  {path: '/user', component: () => import('@/views/user/index')},
  {path: '/files', component: () => import('@/views/files/index')},
  {path: '/film', component: () => import('@/views/film/index')},
  {path: '/business-record', component: () => import('@/views/business-record/index')},
  {path: '/customer-focus', component: () => import('@/views/customer-focus/index')},
  {path: '/', redirect: '/dashboard'},
  {path: '*', redirect: '/dashboard'}
]

// Init Router
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 路由跳转之前，清空（终止）上一个页面正在请求的内容
  Vue._axiosPromiseArr.forEach((ele,index) => {
    ele.cancel() 
    // 清空请求的参数
    delete Vue._axiosPromiseArr[index]
  })

  let username = localStorage.getItem('SPREAD-CRM-USERNAME');

  if (to.path === '/login' && username) {
    // 已经登录的在退出前不在进入登录界面，进入 dashboard 界面
    next('/dashboard')
  } else if (to.path !== '/login' && !username) {
    // 未登录的只能进入登录界面
    next('/login')
  } else if (to.path === '/') {
    // 已经登录的，访问根目录默认跳首页
    next('/dashboard')
  } else {
    next()
  }
})

export default router